.approve-confirm-custom {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #4361ee;
  color: #fff;
  font-size: 14px;
  min-width: 75px;
}

.approve-cancel-custom {
  background-color: #fff;
  color: #4361ee;
  font-weight: 700;
  border: 1px solid #e8e8e8;
  min-width: 75px;
}

.disapprove-confirm-custom {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: rgb(220, 53, 69);
  color: #fff;
  font-size: 14px;
  min-width: 75px;
}

.disapprove-cancel-custom {
  background-color: #fff;
  color: #4361ee;
  font-weight: 700;
  border: 1px solid #e8e8e8;
  min-width: 75px;
}

.btn-blue {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
