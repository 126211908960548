ul.list-unstyled.menu-categories.ps{
  padding: 0 !important;
}

a{
  text-decoration: none !important;

}

.heading span {
  white-space: nowrap; /* Evita que el texto salte a la siguiente línea */
  overflow: hidden; /* Oculta el contenido que desborda */
  text-overflow: ellipsis; /* Añade los puntos suspensivos si el texto es muy largo */
  display: inline-block;
  max-width: 200px; /* Ajusta el ancho máximo según sea necesario */
  vertical-align: middle;
}

.heading {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 0 10px;
  overflow: hidden;
}

