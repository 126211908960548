@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
body {
  font-family: "Nunito";
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header.navbar.navbar-expand-sm {
  min-height: 57px;
}

.sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading {
  color: #fff;
  cursor: initial;
  font-size: 13px;
  padding-top: 15px;
}

#tableDark .table > tbody > tr > td {
  color: #000;
  letter-spacing: inherit;
}

.form-control-standard-width {
  width: inherit;
}

table.dataTable thead th {
  padding: 10px 0px;
}

.input-search {
  width: 90% !important;
  max-width: 300px !important;
}

#example_filter {
  display: none;
}

#example2_filter {
  display: none;
}

#cuadroJson {
  max-height: 300px;
  overflow: auto;
  background-color: red;
}

.paging_simple_numbers {
  padding: 10px !important;
}

.dataTables_info {
  padding: 15px !important;
}

.header.navbar.navbar-expand-sm {
  min-height: 57px;
}

.sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading {
  color: #fff;
  cursor: initial;
  font-size: 13px;
  padding-top: 15px;
}

#tableDark .table > tbody > tr > td {
  color: #000;
  letter-spacing: inherit;
}

.form-control-standard-width {
  width: inherit;
}

.btn-info:hover,
.btn-info:focus {
  background-color: #3e9eea;
}

input::placeholder {
  opacity: 0.2;
  font-size: 13px;
}

.form-form .form-form-wrap form .field-wrapper label {
  font-size: 14px;
  font-weight: normal;
  color: #000;
}

body,
.form-group label,
label {
  color: #000 !important;
}

#examplea th:first-child,
#examplea td:first-child {
  width: 30px;
  /* Ajusta este valor según lo que necesites */
  max-width: 30px;
  min-width: 30px;
  text-align: center;
}
