body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-success {
  color: #fff;
  background-color: #1abc9c !important;
  border-color: #1abc9c !important;
}

.btn-warning {
  color: #fff;
  background-color: #e7515a !important;
  border-color: #e7515a !important;
}

table thead th {
  border-bottom: 1px solid #111;
}

.header.navbar.navbar-expand-sm {
  min-height: 57px;
}

#tableDark .table > tbody > tr > td {
  color: #000;
  letter-spacing: inherit;
}

.form-control-standard-width {
  width: inherit;
}

.btn-info:hover,
.btn-info:focus {
  background-color: #3e9eea;
}

input::placeholder {
  opacity: 0.2;
  font-size: 13px;
}

.form-form .form-form-wrap form .field-wrapper label {
  font-size: 14px;
  font-weight: normal;
  color: #000;
}

body {
  color: #000;
}

#examplea th:first-child,
#examplea td:first-child {
  width: 30px;
  /* Ajusta este valor según lo que necesites */
  max-width: 30px;
  min-width: 30px;
  text-align: center;
}


table tr:last-child td {
  border-bottom: 1px solid #111;
}

.table > thead > tr > th {
  color: #000 !important;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-label::before {
  border-radius: .25rem;
}
.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
*, ::after, ::before {
  box-sizing: border-box;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")
}
