.container-404 {
  text-align: center;
}

.container-404 h1 {
  font-size: 6rem;
  margin: 0;
  color: #ff6b6b;
}

.container-404 h2 {
  font-size: 2rem;
  margin: 20px 0;
}

.container-404 p {
  font-size: 1.2rem;
  color: #666;
}

.container-404 a {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #ff6b6b;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.container-404 a:hover {
  background-color: #ff4d4d;
}

.illustration {
  font-size: 10rem;
  margin-bottom: 20px;
}
