p.p_status {
  color: #000000;
}
p.p_status span {
  color: #555555;
}

.json-pretty {
  word-wrap: break-word;
  height: 300px !important;
  min-height: 300px !important; /* Asegura que el texto largo se ajuste */
}

.__json-pretty__{
  height: 100% !important;
}
