/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
html {
  min-height: 100%;
}

body {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.0312rem;
  font-family: "Nunito";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3b3f5c;
}

:focus {
  outline: none;
}

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #1481d7;
}

::selection {
  color: #fff;
  background: #1481d7;
}

p {
  margin-top: 0;
  margin-bottom: 0.625rem;
  color: #515365;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #f1f2f3;
}

strong {
  font-weight: 600;
}

code {
  color: #e7515a;
}

/*Page title*/
.navbar .navbar-item .nav-item.page-heading {
  margin-left: 20px;
}

.navbar.expand-header .navbar-item .nav-item.page-heading {
  margin-left: 25px;
}

.page-header {
  border: 0;
  margin: 0;
}

.page-title h3 {
  margin: 0;
  margin-bottom: 0;
  font-size: 18px;
  color: #3b3f5c;
  font-weight: 600;
  letter-spacing: 0px;
}

.page-title span {
  display: block;
  font-size: 11px;
  color: #888;
  font-weight: normal;
}

.main-container {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

#container.fixed-header {
  margin-top: 56px;
}

#content {
  width: 50%;
  flex-grow: 8;
  margin-top: 56px;
  margin-bottom: 0;
  margin-left: 255px;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

.main-container-fluid > .main-content > .container {
  float: left;
  width: 100%;
}

#content > .wrapper {
  -webkit-transition: margin ease-in-out 0.1s;
  -moz-transition: margin ease-in-out 0.1s;
  -o-transition: margin ease-in-out 0.1s;
  transition: margin ease-in-out 0.1s;
  position: relative;
}

.widget {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 6px;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}

.layout-top-spacing {
  margin-top: 20px;
}

.layout-spacing {
  padding-bottom: 40px;
}

.layout-px-spacing {
  padding: 0 20px 0 20px !important;
  min-height: calc(100vh - 116px) !important;
}

.widget.box .widget-header {
  background: #fff;
  padding: 0px 8px 0px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.row [class*="col-"] .widget .widget-header h4 {
  color: #3b3f5c;
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  padding: 16px 15px;
}

.seperator-header {
  background: transparent;
  box-shadow: none;
  margin-bottom: 40px;
  border-radius: 0;
}
.seperator-header h4 {
  margin-bottom: 0;
  line-height: 1.4;
  padding: 5px 8px;
  font-size: 15px;
  border-radius: 4px;
  letter-spacing: 1px;
  display: inline-block;
  background: rgba(0, 150, 136, 0.26);
  color: #009688;
  font-weight: 500;
}

.widget .widget-header {
  border-bottom: 0px solid #f1f2f3;
}
.widget .widget-header:before {
  display: table;
  content: "";
  line-height: 0;
}
.widget .widget-header:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}

.widget-content-area {
  padding: 20px;
  position: relative;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.content-area {
  max-width: 58.333333%;
  margin-left: 80px;
}

/* 
=====================
    Navigation Bar
=====================
*/
.header-container {
  padding-left: 48px;
  background: #f1f2f3;
  z-index: 1030;
}

.navbar {
  padding: 0;
  background: #ffffff;
  border-bottom: 0.01rem solid #f1f2f3;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  -webkit-box-shadow: 0px 20px 20px rgba(126, 142, 177, 0.12);
  -moz-box-shadow: 0px 20px 20px rgba(126, 142, 177, 0.12);
  box-shadow: 0px 20px 20px rgba(126, 142, 177, 0.12);
  border-bottom: 1px solid #ebedf2;
}
.navbar.expand-header {
  padding: 0;
}

.navbar-brand {
  width: 5.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-right: 0rem;
}

.navbar .border-underline {
  border-left: 1px solid #ccc;
  height: 20px;
  margin-top: 18px;
  margin-left: -5px;
  margin-right: 8px;
}

.navbar-expand-sm .navbar-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.header-container .sidebarCollapse {
  display: none;
}

.navbar-expand-sm .navbar-item .nav-link {
  line-height: 2.75rem;
  padding: 6.24px 9.6px;
  text-transform: initial;
  position: unset;
}

.navbar .dropdown-menu {
  border-radius: 6px;
  border-color: #e0e6ed;
}

.navbar .dropdown-item {
  line-height: 1.8;
  font-size: 0.96rem;
  padding: 15px 0 15px 0;
  word-wrap: normal;
}

.navbar .navbar-item .nav-item.dropdown.show a.nav-link span {
  color: #805dca !important;
}
.navbar .navbar-item .nav-item.dropdown.show a.nav-link span.badge {
  background-color: #888ea8 !important;
}

.navbar .navbar-item .nav-item .dropdown-item.active,
.navbar .navbar-item .nav-item .dropdown-item:active {
  background-color: transparent;
  color: #16181b;
}

.navbar .navbar-item .nav-item.dropdown .nav-link:hover span {
  color: #805dca !important;
}

.navbar .navbar-item .nav-item.dropdown .dropdown-menu {
  top: 126% !important;
  border-radius: 0;
  border-radius: 6px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
  box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
  background: #fafafa;
}
.navbar .navbar-item .nav-item.dropdown .dropdown-menu.show {
  top: 93% !important;
}

.navbar .language-dropdown {
  align-self: center;
}
.navbar .language-dropdown .custom-dropdown-icon a.dropdown-toggle {
  background-color: #fff;
  position: relative;
  padding: 6px 20px 6px 15px;
  border: none;
  border-radius: 6px;
  transform: none;
  font-size: 14px;
  line-height: 17px;
  min-width: 115px;
  text-align: inherit;
  color: #1b2e4b;
  box-shadow: none;
  max-height: 35px;
  font-weight: 600;
}
.navbar .language-dropdown .custom-dropdown-icon a.dropdown-toggle img {
  width: 22px;
  height: 22px;
  margin-right: 9px;
  vertical-align: text-bottom;
}
.navbar .language-dropdown .custom-dropdown-icon a.dropdown-toggle svg {
  position: absolute;
  right: 4px;
  top: 12px;
  color: #888ea8;
  width: 13px;
  height: 13px;
  margin: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.navbar .language-dropdown .custom-dropdown-icon.show a.dropdown-toggle svg {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.navbar .language-dropdown .custom-dropdown-icon .dropdown-menu {
  position: absolute;
  right: 1px;
  top: 55px !important;
  padding: 8px 0;
  min-width: 155px;
}

.navbar .navbar-item .nav-item.dropdown.language-dropdown .dropdown-menu.show {
  top: 119% !important;
}

.navbar .language-dropdown .custom-dropdown-icon .dropdown-menu a {
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 500;
  color: #3b3f5c;
}
.navbar .language-dropdown .custom-dropdown-icon .dropdown-menu a:hover {
  border-radius: 0 !important;
}

.navbar .language-dropdown .custom-dropdown-icon .dropdown-menu img {
  width: 19px;
  height: 19px;
  margin-right: 7px;
  vertical-align: top;
}

.navbar .navbar-item .nav-item.dropdown.message-dropdown {
  margin-left: 20px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link {
  font-size: 20px;
  padding: 6px 0;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link:after {
  display: none;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link svg {
  color: #515365;
  strokeWidth: 1.5;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .nav-link span.badge {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
  color: #fff !important;
  background: #e2a03f;
  top: 15px;
  right: 2px;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown.double-digit
  .nav-link
  span.badge {
  top: 11px;
  right: 1px;
  width: 22px;
  height: 22px;
  padding: 3px 3px 0px;
  font-size: 9px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
  min-width: 13rem;
  right: -15px;
  left: auto;
  padding: 10px !important;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 8px 7px;
  border: 1px solid #fff;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item.active,
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:active {
  background-color: transparent;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #e0e6ed;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:focus,
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:hover {
  background-color: transparent;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:first-child {
  padding-top: 8px;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu:after {
  right: 17px;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:last-child {
  padding-bottom: 8px;
  cursor: pointer;
}
.navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu .media {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .avatar {
  position: relative;
  display: inline-block;
  width: 39px;
  height: 39px;
  font-size: 14px;
  margin-right: 11px;
  font-weight: 500;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .avatar
  .avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #304aca;
  color: #fff;
  font-weight: 600;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  img {
  width: 40px;
  height: 40px;
  margin-right: 11px;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .media-body
  h5.usr-name {
  font-size: 15px;
  margin-bottom: 0px;
  color: #0e1726;
  font-weight: 500;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .dropdown-item:hover
  .media-body
  h5.usr-name {
  color: #445ede;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .media-body {
  align-self: center;
}
.navbar
  .navbar-item
  .nav-item.dropdown.message-dropdown
  .dropdown-menu
  .media
  .media-body
  p.msg-title {
  font-size: 10px;
  font-weight: 700;
  color: #888ea8;
  margin-bottom: 0;
  letter-spacing: 0;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown {
  margin-left: 20px;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link {
  font-size: 21px;
  padding: 6px 0;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link:after {
  display: none;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
  color: #515365;
  strokeWidth: 1.5;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .nav-link
  span.badge {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 0;
  font-size: 10px;
  color: #fff !important;
  background: #2196f3;
  top: 15px;
  right: 2px;
}
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
  min-width: 15rem;
  right: -5px;
  left: auto;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 0.625rem 1rem;
  cursor: pointer;
  background: transparent;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  svg {
  width: 23px;
  height: 23px;
  font-weight: 600;
  color: #e2a03f;
  fill: rgba(226, 160, 63, 0.270588);
  margin-right: 9px;
  align-self: center;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media.file-upload
  svg {
  color: #e7515a;
  fill: rgba(231, 81, 90, 0.239216);
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media.server-log
  svg {
  color: #009688;
  fill: rgba(0, 150, 136, 0.368627);
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .media-body {
  display: flex;
  justify-content: space-between;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .data-info {
  display: inline-block;
  white-space: normal;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .data-info
  h6 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .dropdown-item:hover
  .data-info
  h6 {
  color: #445ede;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .data-info
  p {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  color: #888ea8;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status {
  display: inline-block;
  white-space: normal;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg.feather-x {
  color: #bfc9d4;
  width: 19px;
  height: 19px;
  cursor: pointer;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg.feather-x:hover {
  color: #e7515a;
}
.navbar
  .navbar-item
  .nav-item.dropdown.notification-dropdown
  .dropdown-menu
  .icon-status
  svg.feather-check {
  color: #fff;
  background: #0d9a5d;
  border-radius: 50%;
  padding: 3px;
  width: 22px;
  height: 22px;
}

.navbar .navbar-item.search-ul {
  margin-right: auto;
}

.navbar .navbar-item .nav-item.search-animated {
  position: relative;
  margin-left: 20px;
}
.navbar .navbar-item .nav-item.search-animated svg {
  font-weight: 600;
  margin: 0 9.6px;
  cursor: pointer;
  color: #888ea8;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 9px;
  strokeWidth: 1.5;
}

.navbar .navbar-item .nav-item form.form-inline input.search-form-control {
  width: 100%;
  font-size: 14px;
  border: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #888ea8;
  letter-spacing: 1px;
  padding: 0px 4px 0px 35px;
  height: 36px;
  font-weight: 500;
}
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus {
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.navbar .navbar-item .nav-item .form-inline.search .search-form-control {
  border: 1px solid #e0e6ed;
  width: 100%;
  width: 425px;
}

.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control::-webkit-input-placeholder,
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control::-ms-input-placeholder,
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control::-moz-placeholder {
  color: #888ea8;
  letter-spacing: 1px;
  font-size: 13px;
}

.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus::-webkit-input-placeholder,
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus::-ms-input-placeholder,
.navbar
  .navbar-item
  .nav-item
  form.form-inline
  input.search-form-control:focus::-moz-placeholder {
  color: #888ea8;
}

/*   Language   */
/*   Language Dropdown  */
/*Message Dropdown*/
/*Notification Dropdown*/
/* Search */
.search-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparent !important;
  z-index: 814 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.search-overlay.show {
  display: block;
  opacity: 0.1;
}

/* User Profile Dropdown*/
.navbar .navbar-item .nav-item.user-profile-dropdown {
  margin-left: 20px;
  margin-right: 20px;
}
.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link.user {
  padding: 6px 0;
  font-size: 25px;
}
.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link.user img {
  width: 28px;
  border-radius: 4px;
  height: 28px;
}

.navbar .navbar-item .nav-item.dropdown.user-profile-dropdown .nav-link:after {
  display: none;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link svg {
  color: #515365;
}

.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .user-profile-section {
  padding: 16px 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-right: -12px;
  margin-left: -12px;
  margin-top: -1px;
  background-image: linear-gradient(to right, #09203f 0%, #537895 100%);
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .user-profile-section
  .media {
  margin: 0;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .user-profile-section
  .media
  img {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 3px solid rgba(224, 230, 237, 0.31);
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .user-profile-section
  .media
  .media-body {
  align-self: center;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .user-profile-section
  .media
  .media-body
  h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  color: #fafafa;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .user-profile-section
  .media
  .media-body
  p {
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0;
  color: #e0e6ed;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .nav-link.user {
  padding: 6px 0;
  font-size: 25px;
}

.navbar .navbar-item .nav-item.dropdown.user-profile-dropdown .nav-link:after {
  display: none;
}

.navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu {
  z-index: 9999;
  max-width: 13rem;
  padding: 0 11px;
  top: 60px;
  left: -140px;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item {
  padding: 0;
  background: transparent;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item
  a {
  display: block;
  color: #3b3f5c;
  font-size: 13px;
  font-weight: 700;
  padding: 9px 14px;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item
  a:hover {
  color: #445ede;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item.active,
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item:active {
  background-color: transparent;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item
  svg {
  width: 17px;
  margin-right: 7px;
  height: 17px;
  color: #030305;
  fill: rgba(3, 3, 5, 0.15);
}
.navbar
  .navbar-item
  .nav-item.user-profile-dropdown
  .dropdown-menu
  .dropdown-item
  a:hover
  svg {
  color: #445ede;
  fill: rgba(3, 3, 5, 0.039);
}

/* 
===============
    Sidebar
===============
*/
.sidebar-wrapper {
  width: 255px;
  position: fixed;
  z-index: 1030;
  transition: 0.6s;
  height: 100vh;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.shadow-bottom {
  display: block;
  position: absolute;
  z-index: 1;
  top: 55px;
  height: 27px;
  width: 95%;
  pointer-events: none;
  margin-top: -8px;
  left: 2px;
  -webkit-filter: blur(5px);
  filter: blur(3px);
  background: -webkit-linear-gradient(
    180deg,
    #030305 44%,
    #030305eb 73%,
    #2c303c00
  );
  background: linear-gradient(180deg, #030305 44%, #030305eb 73%, #2c303c00);
  background: -webkit-linear-gradient(
    180deg,
    #161621e0 44%,
    #161621e3 73%,
    #161621e3
  );
  background: -webkit-linear-gradient(
    180deg,
    #1f1f2d 44%,
    #1f1f2d 73%,
    #1f1f2d
  );
}

.sidebar-closed .shadow-bottom {
  left: -1px;
}

.sidebar-theme {
  background: #262626;
}

.sidebar-closed > .sidebar-wrapper {
  width: 54px;
}
.sidebar-closed > .sidebar-wrapper:hover {
  width: 255px;
}

.sidebar-closed > #content {
  margin-left: 54px;
}

#sidebar .theme-brand {
  position: relative;
  z-index: 2;
  background-color: #262626;
  padding: 0 12px 0 8px;
  /* border-bottom: 1px solid #161621;*/
  justify-content: space-between;
}

.sidebar-closed #sidebar .theme-brand {
  padding: 17.14px 5px;
  justify-content: center;
}

.sidebar-closed > .sidebar-wrapper:hover #sidebar .theme-brand {
  justify-content: space-between;
}

.sidebar-closed .sidebar-wrapper:hover .toggle-sidebar {
  display: block !important;
}

#sidebar .theme-brand .toggle-sidebar {
  align-self: center;
}
#sidebar .theme-brand .toggle-sidebar svg {
  width: 21px;
  height: 21px;
  color: #888e88;
  transition: 500ms;
  vertical-align: baseline;
  cursor: pointer;
}
#sidebar .theme-brand .toggle-sidebar svg polyline:nth-child(2) {
  color: rgba(136, 142, 136, 0.439);
}
#sidebar .theme-brand .toggle-sidebar svg.icon-collapsed {
  display: none;
}

.sidebar-closed #sidebar .theme-brand .toggle-sidebar svg {
  transform: rotate(180deg);
  transition: 0.4s;
  color: #2196f3;
  vertical-align: middle;
}

.sidebar-closed #sidebar:hover .theme-brand .toggle-sidebar svg {
  vertical-align: baseline;
}

.sidebar-closed
  #sidebar
  .theme-brand
  .toggle-sidebar
  svg
  polyline:nth-child(2) {
  color: rgba(32, 148, 243, 0.561);
}

.sidebar-closed #sidebar .theme-brand .toggle-sidebar svg.icon-expanded {
  display: none;
}

.sidebar-closed #sidebar .theme-brand .toggle-sidebar svg.icon-collapsed {
  display: block;
}

.sidebar-closed > .sidebar-wrapper:hover #sidebar .theme-brand {
  padding: 0 12px 0 8px;
}

#sidebar .theme-brand li.theme-logo {
  align-self: center;
}
#sidebar .theme-brand li.theme-logo img {
  width: 33px;
  height: 33px;
}
#sidebar .theme-brand li.theme-logo.theme-text {
  display: flex;
}

.sidebar-closed > .sidebar-wrapper:hover #sidebar .theme-brand li.theme-text a {
  display: block;
}

#sidebar .theme-brand li.theme-text a {
  font-size: 20px !important;
  color: #fff !important;
  line-height: 2.75rem;
  padding: 0.39rem 0.8rem;
  text-transform: initial;
  position: unset;
  font-weight: 700;
  letter-spacing: 1px;
  margin-right: 7px;
}

#sidebar .navbar-brand .img-fluid {
  display: inline;
  width: 44px;
  height: auto;
  margin-left: 20px;
  margin-top: 5px;
}

#sidebar .border-underline {
  border-left: 1px solid #ccc;
  height: 20px;
  margin-top: 18px;
  margin-left: 0px;
  margin-right: 8px;
}

#sidebar * {
  overflow: hidden;
  white-space: nowrap;
}

#sidebar ul.menu-categories {
  position: relative;
  padding: 0px 0 20px 0;
  margin: auto;
  width: 100%;
  overflow: auto;
}
#sidebar ul.menu-categories.ps {
  height: calc(100vh - 57px) !important;
}
#sidebar ul.menu-categories.ps .ps__rail-y:hover {
  background-color: transparent;
}
#sidebar ul.menu-categories.ps:hover > .ps__rail-y .ps__thumb-y {
  background-color: #888ea8;
}
#sidebar
  ul.menu-categories
  li
  > .dropdown-toggle[aria-expanded="true"]
  svg.feather-chevron-right {
  transform: rotate(90deg);
}
#sidebar ul.menu-categories li.menu:first-child ul.submenu > li a {
  justify-content: flex-start;
}
#sidebar ul.menu-categories li.menu:first-child ul.submenu > li a i {
  align-self: center;
  margin-right: 12px;
  font-size: 19px;
  width: 21px;
}

.sidebar-wrapper
  ul.menu-categories
  li.menu.menu-heading
  > .heading
  .feather-circle {
  display: none;
  vertical-align: sub;
  width: 9px;
  height: 9px;
  strokeWidth: 4px;
  color: transparent;
  fill: #506690;
}

.sidebar-closed #sidebar ul.menu-categories li.menu {
  margin: 0 8px;
}

.sidebar-closed #sidebar:hover ul.menu-categories li.menu {
  margin: 0px 0;
}

.sidebar-closed .sidebar-wrapper:hover #sidebar ul.menu-categories {
  padding: 20px 0 20px 0;
}

.sidebar-closed
  .sidebar-wrapper
  ul.menu-categories
  li.menu.menu-heading
  > .heading
  .feather-circle {
  display: inline-block;
}

.sidebar-closed
  .sidebar-wrapper:hover
  ul.menu-categories
  li.menu.menu-heading
  > .heading
  .feather-circle {
  display: none;
}

.sidebar-wrapper ul.menu-categories li.menu.menu-heading > .heading {
  cursor: initial;
  font-size: 13px;
  font-weight: 500;
  padding: 15px 0 5px 20px;
  letter-spacing: 1px;
  color: white;
}

.sidebar-closed
  .sidebar-wrapper
  ul.menu-categories
  li.menu.menu-heading
  > .heading {
  padding: 20px 9px 20px 13px;
}

.sidebar-closed
  > .sidebar-wrapper
  ul.menu-categories
  li.menu.menu-heading
  > .heading
  span {
  display: none;
}

.sidebar-closed
  > .sidebar-wrapper:hover
  ul.menu-categories
  li.menu.menu-heading
  > .heading {
  padding: 40px 0 5px 20px;
}
.sidebar-closed
  > .sidebar-wrapper:hover
  ul.menu-categories
  li.menu.menu-heading
  > .heading
  span {
  display: inline-block;
}

.sidebar-closed #sidebar ul.menu-categories li.menu > .dropdown-toggle {
  padding: 11px 8px;
  transition: 0.6s;
  position: relative;
}

.sidebar-closed
  > .sidebar-wrapper:not(:hover)
  #sidebar
  ul.menu-categories
  li.menu.active
  > .dropdown-toggle
  svg {
  color: #2196f3;
}

.sidebar-closed
  > .sidebar-wrapper:hover
  #sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle {
  padding: 9px 20px;
  transition: 0.6s;
}

.sidebar-closed
  .sidebar-wrapper:hover
  #sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]:before,
.sidebar-closed
  #sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle
  svg.feather-chevron-right {
  display: none;
}

.sidebar-closed
  .sidebar-wrapper:hover
  #sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle
  svg.feather-chevron-right {
  display: inline-block;
}

.sidebar-closed
  .sidebar-wrapper:hover
  #sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]
  svg {
  padding: 0;
  background: transparent;
  border-radius: 0;
  border: none;
}

#sidebar ul.menu-categories li.menu.active .dropdown-toggle {
  background: #1a1a26;
}

#sidebar ul.menu-categories li.menu > .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 13px;
  color: #fff;
  padding: 9px 20px;
  font-weight: 500;
  transition: 0.6s;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle > div {
  align-self: center;
}

#sidebar ul.menu-categories li.menu .dropdown-toggle:after {
  display: none;
}

#sidebar ul.menu-categories li.menu > .dropdown-toggle svg {
  margin-right: 6px;
  vertical-align: middle;
  width: 19px;
  height: 19px;
  strokeWidth: 1.5px;
  /* color: rgba(136, 142, 136, 0.439); */
  color: #adadad;
}

#sidebar
  ul.menu-categories
  li.menu.active
  > .dropdown-toggle
  svg.feather-chevron-right {
  transform: rotate(90deg);
  fill: none;
}

#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]
  svg {
  color: #2196f3;
  fill: none;
}

#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]:hover
  svg {
  color: #2196f3;
}

#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]
  span {
  color: #fff;
}

#sidebar
  ul.menu-categories
  li.menu:not(.active)
  > .dropdown-toggle[aria-expanded="true"] {
  background: #eaf1ff;
  background: #1a1a26;
}

#sidebar ul.menu-categories li.menu > .dropdown-toggle:hover {
  background-color: #1a1a26;
  color: #fff;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle:hover svg {
  color: #2196f3;
}
#sidebar ul.menu-categories li.menu > .dropdown-toggle:hover span {
  color: #e0e6ed;
}

#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle
  svg.feather-chevron-right {
  vertical-align: middle;
  margin-right: 0;
  width: 15px;
  color: #fff;
}

#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]
  svg.flaticon-right-arrow,
#sidebar
  ul.menu-categories
  li.menu
  > .dropdown-toggle[aria-expanded="true"]
  svg.flaticon-down-arrow {
  background-color: transparent;
}

#sidebar ul.menu-categories li.menu > a span:not(.badge) {
  vertical-align: middle;
}

#sidebar ul.menu-categories ul.submenu > li a {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 10px 48px;
  padding-left: 61px;
  font-size: 13px;
  font-weight: 500;
  color: #888ea8;
}

#sidebar ul.menu-categories li.menu ul.submenu > li a:before {
  content: "";
  background-color: #d3d3d3;
  position: absolute;
  height: 3px;
  width: 3px;
  top: 17.5px;
  left: 50px;
  border-radius: 50%;
}

#sidebar ul.menu-categories li.menu ul.submenu > li.active a:before {
  background-color: #2196f3;
}

#sidebar ul.menu-categories ul.submenu > li.active {
  position: relative;
}
#sidebar ul.menu-categories ul.submenu > li.active a {
  color: #fff;
  background: #1a1a26;
  padding: 10px 12px 10px 61px;
}
#sidebar ul.menu-categories ul.submenu > li.active a:hover {
  color: #2196f3;
}
#sidebar ul.menu-categories ul.submenu > li.active a:hover:before {
  background-color: #2196f3 !important;
}

#sidebar ul.menu-categories ul.submenu > li a:hover {
  color: #2196f3;
  background: #1a1a26;
}
#sidebar ul.menu-categories ul.submenu > li a:hover:before {
  background-color: #2196f3 !important;
}

#sidebar ul.menu-categories ul.submenu > li a i {
  align-self: center;
  font-size: 9px;
}

#sidebar ul.menu-categories ul.submenu li > [aria-expanded="true"] i {
  color: #2196f3;
}

#sidebar ul.menu-categories ul.submenu li > [aria-expanded="true"]:before {
  background-color: #2196f3 !important;
}

#sidebar ul.menu-categories ul.submenu li > a[aria-expanded="true"] {
  color: #2196f3;
}

#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a {
  position: relative;
  padding: 10px 12px 10px 48px;
  padding-left: 75px;
  font-size: 11px;
  color: #506690;
}

#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li.active a {
  color: #2196f3;
}

#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:hover {
  color: #2196f3;
}
#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:hover:before {
  background-color: #2196f3;
}

#sidebar ul.menu-categories ul.submenu > li ul.sub-submenu > li a:before {
  content: "";
  background-color: #515365;
  position: absolute;
  top: 15px !important;
  border-radius: 50%;
  left: 0;
  left: 62px;
}

#sidebar
  ul.menu-categories
  ul.submenu
  > li
  ul.sub-submenu
  > li.active
  a:before {
  background-color: #2196f3;
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1035 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  touch-action: pan-y;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.e-animated {
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes e-fadeInUp {
  0% {
    opacity: 0;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.e-fadeInUp {
  -webkit-animation-name: e-fadeInUp;
  animation-name: e-fadeInUp;
}

/*  
    ======================
        Footer-wrapper
    ======================
*/
.footer-wrapper {
  padding: 10px 20px 10px 20px;
  display: inline-block;
  background: transparent;
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  border-top-left-radius: 6px;
  display: flex;
  justify-content: space-between;
}

.main-container.sidebar-closed .footer-wrapper {
  border-radius: 0;
}

.footer-wrapper .footer-section p {
  margin-bottom: 0;
  color: #515365;
  font-size: 13px;
  letter-spacing: 1px;
}
.footer-wrapper .footer-section p a {
  color: #515365;
}

.footer-wrapper .footer-section svg {
  color: #e7515a;
  fill: rgba(231, 81, 90, 0.419608);
  width: 15px;
  height: 15px;
  vertical-align: text-top;
}

/*  
    ======================
        MEDIA QUERIES
    ======================
*/
@media (max-width: 991px) {
  .header-container {
    padding-right: 0;
    padding-left: 0;
  }
  .header-container .sidebarCollapse {
    margin-left: 14px;
    display: block;
  }
  /*
      =============
          NavBar
      =============
  */
  .main-container.sidebar-closed #content {
    margin-left: 0;
  }
  .navbar .navbar-item .nav-item.search-animated {
    margin-left: 0;
  }
  .navbar .language-dropdown .custom-dropdown-icon {
    margin-right: 0;
  }
  .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    width: 425px !important;
  }
  /*
      =============
          Sidebar
      =============
  */
  .page-header {
    display: none;
  }
  #content {
    margin-left: 0;
  }
  #sidebar .theme-brand {
    border-radius: 0;
  }
  .sidebar-closed #sidebar .theme-brand {
    padding: 0 12px 0 8px;
    justify-content: space-between;
  }
  .main-container:not(.sbar-open) .sidebar-wrapper {
    width: 0;
    left: -52px;
  }
  body.alt-menu .sidebar-closed > .sidebar-wrapper {
    width: 255px;
    left: -255px;
  }
  .main-container {
    padding: 0;
  }
  #sidebar ul.menu-categories.ps {
    height: calc(100vh - 114px) !important;
  }
  .sidebar-closed #sidebar ul.menu-categories li.menu {
    margin: 0 0;
  }
  .sidebar-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    border-radius: 0;
    left: 0;
  }
  #sidebar {
    height: 100vh !important;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
  /* display .overlay when it has the .active class */
  .overlay.show {
    display: block;
    opacity: 0.7;
  }
  .navbar .navbar-item .nav-item.dropdown.message-dropdown .dropdown-menu {
    right: -81px;
    padding: 24px 0;
  }
  .navbar
    .navbar-item
    .nav-item.dropdown.message-dropdown
    .dropdown-menu:after {
    right: 80px;
  }
  .navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu {
    right: -42px;
  }
  .navbar
    .navbar-item
    .nav-item.dropdown.notification-dropdown
    .dropdown-menu:after {
    right: 42px;
  }
  .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu {
    right: 1px;
  }
  .navbar .navbar-item .nav-item.user-profile-dropdown .dropdown-menu::after {
    right: 3px;
  }
}

@media (max-width: 1199px) {
  .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    width: 220px;
  }
}

@media (min-width: 992px) {
  header.navbar {
    padding: 0 0 0 207px;
  }
  .sidebar-closed #sidebar .theme-brand li.theme-text a {
    display: none;
  }
  .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    border: 1px solid #e0e6ed;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-item.search-ul {
    margin: 0 0 0 auto;
  }
  .navbar .navbar-item .nav-item .form-inline.search {
    opacity: 0;
    transition: opacity 200ms, top 200ms;
    top: -25px;
  }
  .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    opacity: 0;
    transition: opacity 200ms, top 200ms;
  }
  .navbar .navbar-item .nav-item .form-inline.search.input-focused {
    position: absolute;
    bottom: 0;
    top: 0;
    background: #0e1726;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 32;
    margin-top: 0px !important;
    display: flex;
    opacity: 1;
    transition: opacity 200ms, top 200ms;
  }
  .navbar
    .navbar-item
    .nav-item
    .form-inline.search.input-focused
    .search-form-control {
    opacity: 1;
    transition: opacity 200ms, top 200ms;
  }
  .navbar .navbar-item .nav-item.search-animated {
    position: initial;
    margin-right: 18px;
  }
  .navbar .navbar-item .nav-item.search-animated svg {
    margin: 0;
    cursor: pointer;
    color: #3b3f5c;
    position: initial;
    width: 24px;
    height: 24px;
    transition: top 200ms;
    /* fill: rgba(136, 142, 168, 0.3686274509803922); */
    color: #515365;
    strokeWidth: 1.5;
  }
  .navbar .navbar-item .nav-item.search-animated.show-search svg {
    margin: 0;
    position: absolute;
    top: 17px;
    left: 16px;
    width: 22px;
    height: 22px;
    color: #ebedf2;
    z-index: 40;
    transition: top 200ms;
  }
  .navbar .navbar-item .nav-item .form-inline.search.input-focused .search-bar {
    width: 100%;
  }
  .navbar
    .navbar-item
    .nav-item
    .form-inline.search.input-focused
    .search-form-control {
    background: transparent;
    display: block;
    padding-left: 50px;
    padding-right: 12px;
    border: none;
  }
  .navbar .navbar-item .nav-item .form-inline.search .search-form-control {
    border: none;
    width: 100%;
    display: none;
  }
}

@media (max-width: 575px) {
  .navbar .navbar-item .nav-item.dropdown.message-dropdown,
  .navbar .navbar-item .nav-item.dropdown.notification-dropdown {
    margin-left: 15px;
  }
  .navbar .navbar-item .nav-item.user-profile-dropdown {
    margin-left: 15px;
  }
  .navbar .language-dropdown .custom-dropdown-icon a.dropdown-toggle {
    min-width: 53px;
    padding: 6px 13px 6px 11px;
    border: 1px solid #e0e6ed;
  }
  .navbar .language-dropdown .custom-dropdown-icon a.dropdown-toggle img {
    margin-right: 0;
  }
  .navbar .language-dropdown .custom-dropdown-icon a.dropdown-toggle span {
    display: none;
  }
  .navbar .language-dropdown .custom-dropdown-icon a.dropdown-toggle svg {
    margin-right: -3px;
    top: 11px;
  }
  .footer-wrapper .footer-section.f-section-2 {
    display: none;
  }
}
