.custom-file-container__custom-file {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px; /* Ajustamos la altura a 40px */
    margin-bottom: 0;
    margin-top: 5px;
    cursor: pointer; /* Aseguramos que todo el contenedor sea clicable */
  }
  
  .custom-file-container__custom-file__custom-file-input {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer; /* Asegura que el input file esté en toda el área clicable */
  }
  
  .custom-file-container__custom-file__custom-file-control {
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    line-height: 40px; /* Aseguramos que el texto esté centrado verticalmente */
    user-select: none;
    background-clip: padding-box;
    border-radius: .25rem;
    border: 1px solid #f1f2f3;
    color: #3b3f5c;
    font-size: 15px;
    padding: 0 10px;
    letter-spacing: 1px;
    background-color: #f1f2f3;
  }
  
  .custom-file-container__custom-file__custom-file-control__button {
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
    padding: 0 16px;
    line-height: 40px;
    background-color: #d5d5d5;
    color: #000;
    border-left: 1px solid #e0e6ed;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    cursor: pointer; /* Hacer el botón clicable */
  }
  