.btn-outline-startech {
  border: 1px solid #000000 !important;
  color: #000000 !important;
  background-color: transparent;
  box-shadow: none;
  margin: 0 0.625rem;
}

.customer_font_size {
  font-size: 14px !important;
}
