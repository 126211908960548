#orderTableModal th {
  background-color: #e0e0e0;
  color: black;
  white-space: nowrap;
}

#orderTableModal th, #orderTableModal td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}